<template>
  <transition name="popup">
    <div v-if="show" @click="dismiss" class="black-screen center-items">
      <div
        @click="noevent"
        class="popup-card modal-container"
        :style="{
          width: width,
          height: height,
          'grid-template-rows': noButtons ? 'auto' : 'auto 50px',
        }">
        <div class="body">
          <slot name="body"></slot>
        </div>
        <div class="buttons" :style="{ display: noButtons ? 'none' : '' }">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalPopup",
  props: {
    dismissOnClick: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "20rem",
    },
    height: {
      type: String,
      default: "20rem",
    },
    noButtons: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dismiss(ev) {
      if (this.dismissOnClick) {
        this.$emit("close");
      }
      ev.preventDefault();
    },
    noevent(ev) {
      ev.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.black-screen {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 9998;
}
.popup-card {
  display: grid;
  grid-template-rows: auto 50px;
  background-color: $grey-300;
  border-radius: $border-radius;
  padding: $item-margin;
  z-index: 9999;
}
.buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 2px;
}
.popup-enter-active {
  transition: all 0.2s ease;
}
.popup-enter-active .popup-card {
  animation: grow 0.2s;
}
.popup-leave-active {
  transition: all 0.2s ease;
}
.popup-leave-to {
  opacity: 0;
}
.popup-leave-to .popup-card {
  animation: grow 0.2s reverse;
}
.body {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
