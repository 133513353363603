<template>
  <div class="share-dialog">
    <h4 class="share-title">
      {{ shareString }}
    </h4>
    <div class="share-areas">
      <a :href="twitterUrl" class="share-button button">
        <social-icon class="small-icon" type="twitter"></social-icon>
      </a>
      <a :href="redditUrl" class="share-button button">
        <social-icon class="small-icon" type="reddit"></social-icon>
      </a>
      <a :href="linkedInUrl" class="share-button button">
        <social-icon class="small-icon" type="linkedin"></social-icon>
      </a>
      <a :href="emailUrl" class="share-button button">
        <social-icon class="small-icon" type="email"></social-icon>
      </a>
    </div>
    <div class="share-footer">
      <h4 class="instructions">Or copy+paste the link below:</h4>
      <p class="share-url">{{ url }}</p>
    </div>
  </div>
</template>

<script>
import SocialIcon from "../basic/SocialIcon.vue";
export default {
  components: { SocialIcon },
  name: "DefaultShareDialog",
  props: {
    shareType: {
      type: String,
      default: "user",
    },
    name: String,
    url: String,
    title: String,
  },
  computed: {
    shareString() {
      let string = "Share ";
      switch (this.shareType) {
        case "user":
          string += ` ${this.name}'s profile with others!`;
          break;
        case "post":
          string += ` "${this.name}" with others!`;
      }
      return string;
    },
    twitterUrl() {
      let url = new URL("https://twitter.com/intent/tweet");
      url.searchParams.set("source", "https://icandoathing.com");
      url.searchParams.set("text", this.title);
      url.searchParams.set("url", this.url);
      return url.toString();
    },
    redditUrl() {
      let url = new URL("https://www.reddit.com/submit");
      url.searchParams.set("title", this.title);
      url.searchParams.set("url", this.url);
      return url;
    },
    linkedInUrl() {
      let url = new URL("https://www.linkedin.com/shareArticle");
      url.searchParams.set("mini", true);
      url.searchParams.set("url", this.url);
      url.searchParams.set("title", this.title);
      url.searchParams.set("source", "https://icandoathing.com");
      return url;
    },
    emailUrl() {
      let url = new URL("mailto:");
      url.searchParams.set("body", this.url);
      url.searchParams.set("subject", this.title);
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
.share-dialog {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10% 65% 25%;
  grid-template-areas: "title" "areas" "footer";
  height: 100%;
}
.share-areas {
  grid-area: areas;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  min-height: 80%;
}
.share-button {
  margin: $item-margin;
}
.share-title {
  grid-area: title;
  margin: $item-margin;
}
.share-footer {
  grid-area: footer;
}
.instructions {
  margin: 0.4rem;
}
.share-url {
  background-color: $grey-500;
  color: $white;
  margin: auto;
  padding: 0.3rem;
  width: fit-content;
  border-radius: $border-radius;
  pointer-events: auto;
  user-select: text !important;
}
.small-icon {
  width: 4rem;
  margin: auto;
}
</style>
