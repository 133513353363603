<template>
  <button @click.capture="openShareDialog">
    <p class="button-text">share</p>
    <share-icon></share-icon>
    <modal-popup
      noButtons
      class="modal"
      :show="showSharePopup"
      @close="showSharePopup = false">
      <template v-slot:body
        ><default-share-dialog
          :name="name"
          :shareType="shareType"
          :url="url"
          :title="title"></default-share-dialog
      ></template>
    </modal-popup>
  </button>
</template>

<script>
import ShareIcon from "vue-material-design-icons/ShareVariant.vue";
import ModalPopup from "../basic/ModalPopup.vue";
import DefaultShareDialog from "./DefaultShareDialog.vue";
export default {
  name: "ShareButton",
  components: {
    ShareIcon,
    ModalPopup,
    DefaultShareDialog,
  },
  data() {
    return {
      showSharePopup: false,
    };
  },
  methods: {
    openShareDialog() {
      if (navigator.share) {
        navigator
          .share({
            title: this.title,
            url: this.url,
          })
          .then(() => {
            console.log("Thanks for sharing!");
          })
          .catch(console.error);
      } else {
        this.showSharePopup = true;
        // console.log("no done yet");
      }
    },
  },
  computed: {
    title() {
      if (this.shareType == "user") {
        return "Check out " + this.name + "'s profile on icandoathing.com!";
      } else if (this.shareType == "post") {
        return 'Check out "' + this.name + '" on icandoathing.com!';
      } else {
        return "Check out icandoathing.com!";
      }
    },
  },
  props: {
    shareType: {
      type: String,
      default: "user",
    },
    name: String,
    url: String,
  },
};
</script>

<style lang="scss" scoped>
.modal {
  cursor: auto;
}
</style>
